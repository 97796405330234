// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  authUrl: 'https://idp.jurta.kz/auth/realms/htc/protocol/openid-connect/token',
  apiDataManagerUrl: 'https://dm.jurta.kz',
  apiFileManagerUrl: 'https://fm.jurta.kz',
  apiViewManagerUrl: 'https://vm.jurta.kz',
  apiUserManagerUrl: 'https://um.jurta.kz',
  apiGisManagerUrl:  'https://gm.jurta.kz',
  apiEducationManagerUrl: 'https://em.jurta.kz',
  apiVideoUrl:  'https://storage-api.jurta.kz/',
  appPlayMarketLink: 'https://play.google.com/store/apps/details?id=kz.jurta.app&hl=ru&gl=US',
  appAppStoreLink: 'https://apps.apple.com/ua/app/jurta/id1595532580?l=ru',

  appDefaultSettings: {
    countries: {
      uzbekistan: {
        id: 3882,
        code: '003',
        name: {
          nameRu: 'Узбекистан',
          nameKz: 'Өзбекстан',
          nameEn: 'Uzbekistan',
        },
      },
    },
    cities: {
      kazakhstan: 1,
      uzbekistan: 3883,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
